import { useDisplay as useVuetifyDisplay } from 'vuetify';

export function useDisplay() {
  const { smAndUp } = useVuetifyDisplay();

  return {
    get isTouchDevice() {
      return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        // @ts-expect-error: account for MS screens
        navigator.msMaxTouchPoints > 0
      );
    },

    get isSmallScreen() {
      if (smAndUp.value) return false;

      return true;
    },
  };
}
